import { browserTracingIntegration, init as sentryInit } from '@sentry/react';
import AuthProvider from 'isc-shared/auth/Provider';
import 'isc-shared/styles/global.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import './layouts/global.css';
import env from './lib/env';
import { router } from './lib/routing';
import NotifierProvider from 'isc-shared/ui/notifier/Provider';

const IS_PRODUCTION = env('MODE') === 'production'; // NOTE: This includes staging!
const IS_STAGING = Boolean(env('VITE_STAGING', false));

if (IS_PRODUCTION) {
    sentryInit({
        dsn: "https://48a8001a455e49b8a421057186d145fe@o283657.ingest.sentry.io/4505160342306816",
        integrations: [browserTracingIntegration()],
        environment: IS_STAGING ? 'staging': 'production',
        release: VITE_BUILD_TIMESTAMP,
    });
}

const root = document.getElementById('root');

if (!root) {
    throw new Error('No #root element found.');
}

ReactDOM.createRoot(root).render(
    <React.StrictMode>
        <HelmetProvider>
            <NotifierProvider>
                <AuthProvider>
                    <RouterProvider router={router} />
                </AuthProvider>
            </NotifierProvider>
        </HelmetProvider>
    </React.StrictMode>,
);
