import bbox from '@turf/bbox';
import env from './env';

// https://www.quora.com/What-is-the-longitude-and-latitude-of-a-bounding-box-around-the-continental-United-States
export const CONTIGUOUS_USA_BBOX = [-124.848974, 24.396308, -66.885444, 49.384358] as const;

const MAPBOX_API_TOKEN = env('VITE_MAPBOX_API_TOKEN');
const GEOCODING_API_ROOT = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

export type GeocodeFeature = {
    /** Note the user can move this, so it might not be what the API returns! */
    center: [number, number];
    // context: { id: string, text: string, short_code?: string }[];
    bbox?: [number, number, number, number];
    id: string;
    place_name: string;
    place_type: string[];
    text: string;
};

export async function geocode(search: string, nearIp = true) {
    const url = new URL(`${GEOCODING_API_ROOT}/${search}.json`);
    url.searchParams.set('access_token', MAPBOX_API_TOKEN);
    if (nearIp) url.searchParams.set('proximity', 'ip');
    const response = await fetch(url);
    const json = await response.json();
    return json.features as GeocodeFeature[];
}

export async function reverseGeocode(lng: number, lat: number) {
    const url = new URL(`${GEOCODING_API_ROOT}/${lng},${lat}.json`);
    url.searchParams.set('access_token', MAPBOX_API_TOKEN);
    const response = await fetch(url);
    const json = await response.json();
    return json.features as GeocodeFeature[];
}

export function getCurrentLocation(): ReturnType<typeof reverseGeocode> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(result => {
            const { longitude, latitude } = result.coords;
            resolve(reverseGeocode(longitude, latitude));
        }, reject, {
            enableHighAccuracy: true
        });
    });
}

export function get2dBbox(geojson: Parameters<typeof bbox>[0]) {
    const box = bbox(geojson);
    return [box[0], box[1], box[2], box[3]] as GeocodeFeature['bbox'];
}

// export function collectAddressComponents(location: GeocodeFeature) {
//     const REPLACED_KEYS = { locality: 'city', postcode: 'zipcode', region: 'state' };

//     const addressComponents: Record<string, string> = {};

//     for (const item of location.context) {
//         const contextIdPrefix = item.id.split('.')[0]!;
//         let componentKey = contextIdPrefix;
//         if (contextIdPrefix in REPLACED_KEYS) {
//             componentKey = REPLACED_KEYS[contextIdPrefix as keyof typeof REPLACED_KEYS];
//         }

//         addressComponents[componentKey] = item.text;

//         if (item.short_code) {
//             addressComponents[`${componentKey}Code`] = item.short_code;
//         }
//     }

//     return addressComponents;
// }
